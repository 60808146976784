import * as React from 'react';

import {LabelledImageProps} from 'src/components/shared';

import {SanityImageWithAlt} from 'graphql-types';
import {Maybe, SanityBlock, SanityEditorial} from 'types/generated';

import {PodcastArticleTemplate} from './PodcastArticle';

export default ({
  pageContext,
  location,
}: {
  pageContext: {
    podcastEditorial: SanityEditorial;
    megaphoneLink: string;
    podcastDescription?: Maybe<Array<Maybe<SanityBlock>>>;
    podcastTranscript?: Maybe<Array<Maybe<SanityBlock>>>;
    episodeImage?: Maybe<SanityImageWithAlt>;
    relatedArticles: [Maybe<SanityEditorial>];
    next: {
      slug: string;
      title: Maybe<string>;
      episodeImage: Maybe<SanityImageWithAlt>;
      content: JSON;
      editorialCategory: JSON;
      category: Maybe<string>;
    };
    href: string;
  };
  location: Location;
}): React.ReactElement => (
  <PodcastArticleTemplate
    podcastEditorial={pageContext.podcastEditorial}
    megaphoneLink={pageContext.megaphoneLink}
    podcastDescription={pageContext.podcastDescription}
    podcastTranscript={pageContext.podcastTranscript}
    episodeImage={pageContext.episodeImage}
    next={pageContext.next}
    href={pageContext.href}
    relatedArticles={pageContext.relatedArticles}
  />
);
